<template>
  <div class="hero_wrapper">
      <div class="hero_content">
         <h1> {{ heroHeadline }} </h1>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Hero',
  props: [
   'heroHeadline',
  ]
  }
</script>

<style>

.hero_wrapper {
  height: 400px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.hero_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero_content h1 {
  text-align: center;
}

</style>

