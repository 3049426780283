<template>
  <div class="header">
      <div class="nav_link">
          <p>
            <router-link to="/">Home</router-link> | 
            <router-link to="/about">About</router-link>
          </p>
        </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  }
</script>

<style>

.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
}

.hetty_logo img {
    width: 40px;
}

.nav_link {
    margin-right: 20px;
}
.nav_link a {
    text-decoration: none;
    color: inherit;
}
</style>

