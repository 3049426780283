import { createWebHistory, createRouter } from "vue-router"
import Home from "@/views/home"
import About from "@/views/about"

const routes = [
{ 
    path: '/',
    name: 'Home',
    component: Home 
},
{ 
    path: '/about',
    name: 'About', 
    component: About 
}
]

const router = createRouter({
    history: createWebHistory(),
    routes
  })

export default router