<template>
  <div class="bpi_wrapper">
    <h2 class="bpi_headline">Bitcoin Price Index</h2>
    <div class="bpi_currencies">
      <p v-for="currency in data" 
        :key="currency.code"
        class="bpi_currency-code"
        :class="'bpi_currency-code--' + currency.code.toLowerCase()"
        > {{ currency.code }}:
        <span 
          class="bpi_currency-rate"
          :class="'bpi_currency-rate--' + currency.code.toLowerCase()">
          <span v-html="currency.symbol"></span>{{ currency.rate_float.toFixed(2) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BitcoinPriceIndex',
  data()  {
      return {
        data: null,
      }
  },
  mounted() {
      axios
        .get('https://api.coindesk.com/v1/bpi/currentprice.json')
        .then(response => (this.data = response.data.bpi))
  },
}
</script>

<style>

@media (min-width: 760px) {
  .bpi_wrapper {
    width: 50%;
    margin: 0 auto;
  }
}

.bpi_headline {
  text-align: center;
}

.bpi_currencies {
  width: 50%;
  margin:0 auto;
  text-align: center;
}

.bpi_currencies p {
  font-weight: bold;
}

</style>

