<template>
  <div class="home">
    <Hero :hero-headline="this.heroHeadline" />
    <p class="about_blurb">Hetty Trades LLC &#169;2022  </p>
  </div>
</template>

<script>
import Hero from '@/components/hero'

export default {
  name: 'About',
  components: {
    Hero,
  },
  data() {
    return {
      heroHeadline: 'About'
    }
  }
}
</script>

<style>
p.about_blurb {
  margin-top: 100px;
  text-align: center;
}
</style>
