<template>
  <div class="home">
    <Hero :hero-headline="this.heroHeadline"/>
    <BitcoinPriceIndex />
  </div>
</template>

<script>
import BitcoinPriceIndex from '@/components/bitcoin-price-index'
import Hero from '@/components/hero'

export default {
  name: 'Home',
  components: {
    BitcoinPriceIndex,
    Hero
  },
  data() {
    return {
      heroHeadline: 'Hetty Trades'
    }
  }
}
</script>

<style>

</style>
